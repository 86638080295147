/* eslint-disable @next/next/no-img-element */
import { Logo } from '@/components/dashboard/Layout/NavbarHeader'
import UserMenu from '@/components/dashboard/Layout/UserMenu'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { Fragment, useEffect, useState } from 'react'
import ClientLocaleSwitcher from './LocaleButton'
import Notifications from './Notifications'

const Header = ({ mode = 'light' }: { mode?: 'dark' | 'light' }) => {
  const { t, lang } = useTranslation('client')
  const isArabic = lang === 'ar'
  const { data } = useSession()
  const isSignedIn = !!data
  const router = useRouter()
  const locale = router.locale === 'en' ? '/en' : '/ar'

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header
      className={`sticky top-0 z-50 ${
        scrollY > 0 || mode === 'dark' ? 'shadow-md popover-transparent' : ''
      }`}
    >
      <Popover
        className={`relative ${
          scrollY > 0 || mode === 'dark' ? 'popover-bg' : ''
        }`}
      >
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:gap-2 lg:px-8">
          <div className="">
            <Link href={`${locale}`}>
              <a>
                <span className="sr-only">{t('appName')}</span>
                <Logo
                  color={scrollY > 0 || mode === 'dark' ? 'dark' : 'light'}
                />
              </a>
            </Link>
          </div>

          <div className="-mx-2 -my-2 md:hidden flex justify-center items-center">
            <div className="mx-2 flex">
              <ClientLocaleSwitcher />
              <Notifications />
            </div>
            <Popover.Button className=" rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex gap-8">
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : '{(scrollY > 0 || mode === 'dark' ) ? text-white : text-gray-500}',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
                    )}
                  >
                    <span>Solutions</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:{(scrollY > 0 || mode === 'dark' ) ? text-white : text-gray-500}',
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                          {solutions.map(item => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white sm:h-12 sm:w-12">
                                <item.icon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm {(scrollY > 0 || mode === 'dark' ) ? text-white : text-gray-500}">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}

            <Link href={`${locale}/events`}>
              <a
                className={`text-base  ${
                  scrollY > 0 || mode === 'dark'
                    ? 'text-white '
                    : 'text-gray-500 font-medium'
                } hover:text-gray-900`}
              >
                {t('courses')}
              </a>
            </Link>

            <Link href={`${locale}/profile/become_trainer`}>
              <a
                className={`text-base  ${
                  scrollY > 0 || mode === 'dark'
                    ? 'text-white '
                    : 'text-gray-500 font-medium'
                } hover:text-gray-900`}
              >
                {t('becomeTrainer')}
              </a>
            </Link>
            <Link href={`${locale}/profile?i=2`}>
              <a
                className={`text-base  ${
                  scrollY > 0 || mode === 'dark'
                    ? 'text-white '
                    : 'text-gray-500 font-medium'
                } hover:text-gray-900`}
              >
                {t('requestCourse')}
              </a>
            </Link>
            {/* <a
              href="#"
              className={`text-base  ${(scrollY > 0 || mode === 'dark' ) ?  'text-white '
                    : 'text-gray-500 font-medium'} hover:text-gray-900`}
            >
              {t('services')}
            </a> */}
            {/* <a
              href="#"
              className={`text-base  ${
                scrollY > 0 || mode === 'dark'
                  ? 'text-white '
                  : 'text-gray-500 font-medium'
              } hover:text-gray-900`}
            >
              {t('mediaCenter')}
            </a> */}

            {/* <a
              href={`${locale}/about-us`}
              className={`text-base  ${
                scrollY > 0 || mode === 'dark'
                  ? 'text-white '
                  : 'text-gray-500 font-medium'
              } hover:text-gray-900`}
            >
              {t('aboutUs')}
            </a> */}

            <Link href={`${locale}/events?archived=true`}>
              <a
                className={`text-base  ${
                  scrollY > 0 || mode === 'dark'
                    ? 'text-white '
                    : 'text-gray-500 font-medium'
                } hover:text-gray-900`}
              >
                {t('archive')}
              </a>
            </Link>
            <Link href={`${locale}/links/contact`}>
              <a
                className={`text-base  ${
                  scrollY > 0 || mode === 'dark'
                    ? 'text-white '
                    : 'text-gray-500 font-medium'
                } hover:text-gray-900`}
              >
                {t('contact')}
              </a>
            </Link>
            <Link href={`${locale}/blogs`}>
              <a
                className={`text-base  ${
                  scrollY > 0 || mode === 'dark'
                    ? 'text-white '
                    : 'text-gray-500 font-medium'
                } hover:text-gray-900`}
              >
                {t('blogs')}
              </a>
            </Link>
          </Popover.Group>

          <div
            className={`hidden md:flex items-center justify-end ${
              scrollY > 0 || mode === 'dark'
                ? 'text-white '
                : 'text-gray-500 font-medium'
            }`}
          >
            <Notifications />
            <ClientLocaleSwitcher scrollY={scrollY} />
            <UserMenu scrollY={scrollY} />
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <Link href="/">
                    <a>
                      <Logo />
                    </a>
                  </Link>

                  <div className="-mr-2">
                    <Popover.Button className="bg-gray rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                {/* <div className="mt-6">
                  <nav className="grid grid-cols-1 gap-7">
                    {solutions.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {item.name}
                        </div>
                      </a>
                    ))}
                  </nav>
                </div> */}
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  {isSignedIn ? (
                    <Link href={`${locale}/profile`}>
                      <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                        {t('myProfile')}
                      </a>
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        router.push(
                          `${locale}/auth/signin?callbackUrl=${locale}${router.asPath}`,
                        )
                      }
                      className="text-base font-medium text-gray-900 hover:text-gray-700 text-start"
                    >
                      {t('signIn')}
                    </button>
                  )}

                  <Link href={`${locale}/events`}>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {t('courses')}
                    </a>
                  </Link>
                  <Link href={`${locale}/profile/become_trainer`}>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {t('becomeTrainer')}
                    </a>
                  </Link>
                  <Link href={`${locale}/profile?i=2`}>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {t('requestCourse')}
                    </a>
                  </Link>
                  <Link href={`${locale}/events?archived=true`}>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {t('archive')}
                    </a>
                  </Link>
                  {/* <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {t('services')}
                  </a> */}
                  {/* <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {t('mediaCenter')}
                  </a> */}
                  <Link href={`${locale}/links/contact`}>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {t('contact')}
                    </a>
                  </Link>
                  <Link href={`${locale}/blogs`}>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {t('blogs')}
                    </a>
                  </Link>
                  {isSignedIn && (
                    <button
                      type="button"
                      onClick={() =>
                        signOut({ redirect: true, callbackUrl: `${locale}` })
                      }
                      className="text-base font-medium text-gray-900 hover:text-gray-700 text-start"
                    >
                      {t('signOut')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}

export default Header
