/* eslint-disable @next/next/no-img-element */
import { Logo } from '@/components/dashboard/Layout/NavbarHeader'
import dayjs from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { AiFillInstagram } from 'react-icons/ai'
import { BsLinkedin, BsTelegram } from 'react-icons/bs'

const Footer = () => {
  const { t } = useTranslation('client')
  const URL = 'https://infinity.edu.sa'
  const navigation = {
    // services: [
    //   { name: t('forinsicMedicalSciences'), href: '#' },
    //   { name: t('continingProfessionalDevelopment'), href: '#' },
    //   { name: t('externalTrainingStudentTraining'), href: '#' },
    //   { name: t('lifeSupportTraining'), href: '#' },
    //   { name: t('research'), href: '#' },
    //   { name: t('trainingDevelopment'), href: '#' },
    // ],
    links: [
      { name: t('courses'), href: '/events' },
      // { name: t('archivedCourses'), href: '/events?archived=true' },
      // { name: t('mediaCenter'), href: '#' },
      { name: t('requestCourse'), href: '/profile?i=2' },
      { name: t('becomeTrainer'), href: '/profile/become_trainer' },
      { name: t('termsAndConditions'), href: '/links/terms?tab=7' },
      { name: t('informationPolicy'), href: '/links/information-policy?tab=8' },
      { name: t('elearningPolicy'), href: '/about-us?tab=10' },
    ],
    pdfinks: [
      {
        name: t('pdf0'),
        href: '/app-pdf/الدليل-الارشادي-للمتدرب-والتقنيات-المستخدمة.pdf',
      },
      {
        name: t('pdf1'),
        href: '/app-pdf/الدليل-الارشادي-للمدرب-والتقنيات-المستخدمة.pdf',
      },
      {
        name: t('pdf2'),
        href: '/app-pdf/الحقوق-الملكية-الفكرية-وحقوق-النشر.pdf',
      },
      { name: t('pdf3'), href: '/app-pdf/النزاهة-الاكاديمية-بيئة-التعليم.pdf' },
      { name: t('pdf4'), href: '/app-pdf/آلية-لرفع-الشكاوى-والمقترحات.pdf' },
      { name: t('pdf5'), href: '/app-pdf/أنظمة-للتحقق-من-هوية-المستفيد.pdf' },
      { name: t('pdf6'), href: '/app-pdf/سياسات-الدعم-الفني.pdf' },
      { name: t('pdf7'), href: '/app-pdf/سياسة-الحضور-الإلكتروني.pdf' },
      { name: t('pdf8'), href: '/app-pdf/وثيقة-الأدوار-والمسؤوليات.pdf' },
    ],
  }
  return (
    <footer
      className="bg-secondary text-white"
      aria-labelledby="footer-heading"
    >
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="lg:grid lg:grid-cols-4 lg:gap-8 flex items-center sm:flex-row flex-col">
          <div className="space-y-8 lg:col-span-1">
            <Logo color="dark" />
            <p className="text-white text-base max-w-lg">
              {t('footerDescription')}
            </p>
          </div>

          {/* <div>
            <h3 className="text-sm font-bold text-white  tracking-wider uppercase">
              {t('pdfTitle')}
            </h3>
            <ul className="mt-4 space-y-4">
              {navigation.pdfinks.map(item => (
                <li key={item.name}>
                  <Link target="_blank" href={URL + item.href}>
                    <a
                      target="_blank"
                      className="text-base text-gray-50 hover:text-gray-300"
                    >
                      {item.name}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}

          <div className="flex flex-col gap-6 lg:col-span-3 mt-9 ml-4">
            {/* <h3 className="text-sm font-bold text-white  tracking-wider uppercase">
              {t('links')}
            </h3> */}
            {/* <ul className="mt-4 space-y-4"> */}
            {/* <li key={item.name}> */}
            <div className="flex gap-5 items-center sm:flex-row flex-col">
              {navigation.links.map(item => (
                <Link href={item.href}>
                  <a className="text-base font-bold text-gray-50 text-start hover:text-gray-300">
                    {item.name}
                  </a>
                </Link>
              ))}

              {/* </li> */}
              {/* </ul> */}

              <div className="flex gap-4 items-center ml-3">
                {/* <h3 className="text-sm font-bold text-white  tracking-wider uppercase">
              {t('socialMedia')}
            </h3> */}
                {/* <ul className="mt-4 space-y-4"> */}
                {/* <li> */}
                <a
                  className="text-base text-gray-50 hover:text-gray-300 flex items-center gap-2"
                  href="https://www.instagram.com/infinity_edu_sa"
                >
                  <AiFillInstagram
                    className="w-8 h-8 text-[#32a6d8]"
                    size={48}
                  />
                  {/* <span>@infinity_edu_sa</span> */}
                </a>
                {/* </li> */}

                {/* <li> */}
                <a
                  href="https://www.facebook.com/infinity.edu.sa/"
                  className="text-base text-gray-50 hover:text-gray-300 flex items-center gap-2"
                >
                  <svg
                    className="w-6 h-6 text-[#1773ea]"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {/* <span>@ihc.edu111</span> */}
                </a>
                {/* </li> */}
                {/* <li> */}
                <a
                  href="https://twitter.com/infinity_edu_sa"
                  className="text-base text-gray-50 hover:text-gray-300 flex items-center gap-2"
                >
                  <svg
                    className="w-6 h-6 text-[#1c96e8]"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>

                  {/* <span>@infinity_edu_sa</span> */}
                </a>
                {/* </li> */}
                {/* <li> */}
                <a
                  href="https://t.me/infinity_edu_sa"
                  className="text-base text-gray-50 hover:text-gray-300 flex items-center gap-2"
                >
                  <BsTelegram className="w-6 h-6 text-[#32a6d8]" />

                  {/* <span>@infinity_edu_sa</span> */}
                </a>
                {/* </li> */}
                {/* <li> */}
                <a
                  href="https://www.linkedin.com/company/infinity-edu-sa/"
                  className="text-base text-gray-50 hover:text-gray-300 flex items-center gap-2"
                >
                  <BsLinkedin className="w-6 h-6 text-[#0a63bc]" />

                  {/* <span>@infinity_edu_sa</span> */}
                </a>
                {/* </li> */}
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <div className="flex flex-col items-center flex-wrap gap-4">
            <div className="flex flex-col items-center gap-2 text-xs text-gray-100 md:flex-row">
              <p>Flexible Payment Options</p>
              <div className="flex items-center gap-4 scale-95">
                <div>
                  <img
                    src="/icons/payments/mada.svg"
                    width="41"
                    height="15"
                    alt="Mada Payment Logo"
                  />
                </div>
                <div>
                  <img
                    src="/icons/payments/apple-pay.svg"
                    width="41"
                    height="17"
                    alt="Apple Payment Logo"
                  />
                </div>
                <div>
                  <img
                    src="/icons/payments/visa.svg"
                    width="24"
                    height="23"
                    alt="Visa Payment Logo"
                  />
                </div>
                <div>
                  <img
                    src="/icons/payments/mastercard.svg"
                    width="29"
                    height="23"
                    alt="Mastercard Payment Logo"
                  />
                </div>
              </div>
            </div>

            <p className="text-bold text-white  xl:text-center">
              &copy; {dayjs(new Date()).format('YYYY')} {t('appName')}, Inc. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
