import { ActionIcon } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { BsGlobe } from 'react-icons/bs'

const LocaleSwitcher = ({ color = 'gray' }: { color?: string }) => {
  const router = useRouter()
  const { locales, locale: activeLocale } = router
  const otherLocales = locales?.filter(locale => locale !== activeLocale) ?? []

  return (
    <div>
      {otherLocales.map(locale => {
        const { pathname, query, asPath } = router
        const isArabic = locale === 'ar'
        return (
          <Link
            href={{ pathname, query }}
            as={asPath}
            locale={locale}
            key={locale}
          >
            <a>
              <span className="flex items-center justify-center">
                <span className={`mx-2 hidden md:inline-block text-${color}`}>
                  {!isArabic ? 'English' : 'العربية'}
                </span>
                <ActionIcon
                  variant="outline"
                  color={color}
                  title="Toggle color scheme"
                  className=""
                >
                  <BsGlobe />
                </ActionIcon>
              </span>
            </a>
          </Link>
        )
      })}
    </div>
  )
}

LocaleSwitcher.defaultProps = {
  color: 'gray',
}

export default LocaleSwitcher
