import GradientButtonLink from '@/components/client/components/button/GradientButtonLink'
import { Group, Menu } from '@mantine/core'
import UserButton from 'components/common/button/UserButton'
import { signOut, useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { MdPersonOutline } from 'react-icons/md'
import { useRouter } from 'next/router'

const UserMenu = ({ scrollY }: { scrollY: number }) => {
  const { t } = useTranslation('client')
  const { data } = useSession()
  const isSignedIn = !!data?.user
  const router = useRouter()
  const locale = router.locale === 'en' ? '/en' : '/ar'

  if (isSignedIn)
    return (
      <Group position="center">
        <Menu
          withArrow
          placement="center"
          control={
            <UserButton
              image={data?.user?.image as string}
              name={data?.user?.name as string}
              username={data?.user?.username as string}
              email={data?.user?.email as string}
              scrollY={scrollY}
            />
          }
        >
          <Menu.Item
            icon={<MdPersonOutline size={16} />}
            onClick={() => router.push(`${locale}/profile`)}
          >
            <span className="mx-2">{t('profile')}</span>
          </Menu.Item>
          <Menu.Item
            icon={<MdPersonOutline size={16} />}
            onClick={() =>
              signOut({ redirect: true, callbackUrl: `${locale}` })
            }
          >
            <span className="mx-2"> {t('signOut')}</span>
          </Menu.Item>
        </Menu>
      </Group>
    )

  return (
    <>
      <GradientButtonLink
        herf={`${locale}/auth/signin?callbackUrl=${locale}${router.asPath}`}
      >
        {t('signIn')}
      </GradientButtonLink>

      <Link href={`${locale}/auth/signup`}>
        <a>
          <span className="mx-2">{t('signUp')}</span>
        </a>
      </Link>
    </>
  )
}

export default UserMenu
