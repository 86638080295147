import Link from 'next/link'
import { ReactNode } from 'react'

const GradientButtonLink = ({
  herf,
  children,
}: {
  herf: string
  children: ReactNode | ReactNode[]
}) => (
  <Link href={herf}>
    <a className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-primary to-secondary bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white drop-shadow-sm hover:drop-shadow-lg">
      {children}
    </a>
  </Link>
)

export default GradientButtonLink
