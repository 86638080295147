import Link from 'next/link'
import { useRouter } from 'next/router'

const ClientLocaleSwitcher = ({ scrollY = 0 }: { scrollY?: number }) => {
  const router = useRouter()
  const { locales, locale: activeLocale } = router
  const otherLocales = locales?.filter(locale => locale !== activeLocale) ?? []

  return (
    <div>
      {otherLocales.map(locale => {
        const { pathname, query, asPath } = router
        const isArabic = locale === 'ar'
        return (
          <Link
            href={{ pathname, query }}
            as={asPath}
            locale={locale}
            key={locale}
          >
            <a>
              <span
                className={`text-base font-medium ${
                  scrollY > 0
                    ? 'text-white'
                    : 'text-gray-900 hover:text-gray-700'
                }  mx-4`}
              >
                {!isArabic ? 'English' : 'العربية'}
              </span>
            </a>
          </Link>
        )
      })}
    </div>
  )
}

export default ClientLocaleSwitcher
