/* eslint-disable react/require-default-props */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Burger, MediaQuery, Title, useMantineTheme } from '@mantine/core'
import ChangeThemeIcon from 'components/common/button/ChangeThemeIcon'
import LocaleSwitcher from 'components/common/button/LocaleSwicher'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import UserMenu from './UserMenu'

const NavbarHeader = ({
  opened,
  setOpened,
}: {
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')

  return (
    <div className="flex justify-between items-center h-full">
      <div className="flex items-center">
        <MediaQuery largerThan="xl" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={() => setOpened(o => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="sm"
          />
        </MediaQuery>
        <Link href="/dashboard">
          <a>
            <Logo />
          </a>
        </Link>

        <Title order={5} className="hidden lg:inline px-2">
          {t('appName')}
        </Title>
      </div>
      <div className="flex items-center">
        <LocaleSwitcher />
        <ChangeThemeIcon />
        <UserMenu />
      </div>
    </div>
  )
}

export default NavbarHeader

export const Logo = ({ color }: { color?: 'dark' | 'light' }) => {
  const theme = useMantineTheme()

  const colorScheme = color ?? theme.colorScheme

  if (colorScheme === 'dark')
    return <img src="/app-images/logo.png" alt="" className="w-36 h-12" />

  return <img src="/app-images/logo-light.png" alt="" className="w-36 h-12" />
}
