/* eslint-disable */

import { Avatar, Box, Group, Text, UnstyledButton } from '@mantine/core'
import { forwardRef } from 'react'

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image?: string
  username?: string
  name?: string
  email?: string
  date?: string
  scrollY?: number
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  (
    { image, name, email, username, date, scrollY, ...others }: UserButtonProps,
    ref,
  ) => (
    <UnstyledButton
      ref={ref}
      sx={() => ({
        display: 'block',
        width: '100%',
        // padding: theme.spacing.md,
        opacity: 0.9,

        '&:hover': {
          opacity: 1,
        },
      })}
      {...others}
    >
      <Group dir="ltr" style={{ gap: '3px', justifyContent: 'center' }}>
        <Avatar src={image} radius="xl" />

        <Box>
          {/* (theme: { breakpoints: { md: any } }) => ({
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
              display: 'none',
            },
          }) */}
          <Text
            size="sm"
            color={scrollY && scrollY > 0 ? 'white' : ''}
            className="truncate w-14"
            weight={500}
          >
            {username}
          </Text>
          {/* <Text size="sm" weight={500}>
            {name}
          </Text>*/}

          <Text color={scrollY && scrollY > 0 ? 'white' : 'dimmed'} size="xs">
            {date}
          </Text>
        </Box>
      </Group>
    </UnstyledButton>
  ),
)
UserButton.displayName = 'UserButton'

export default UserButton
