/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react'
import { ColorSchemeProvider, MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { ROLE } from 'db'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import stylisRTLPlugin from 'stylis-plugin-rtl'
import Footer from './Footer'
import Header from './Header'
/* import { useMutation } from '@apollo/client'
import { UpdateProfileDocument } from '@/types/graphql/types'
import localforage from 'localforage'
import { getMessaging, onMessage, isSupported } from 'firebase/messaging'
import { showNotification } from '@mantine/notifications'
import firebaseApp from '@/util/firebase'
import { sendTelegramMessage } from '@/util/telegram'
import { getBrowserType } from '@/util/browserType'
import { FcmDialog } from './FcmDialog' */

export const ClientStyleProvider = ({ children }: { children: any }) => {
  const { lang } = useTranslation('common')
  const isArabic = lang === 'ar'

  return (
    <ColorSchemeProvider colorScheme="light" toggleColorScheme={() => {}}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionOptions={
          isArabic
            ? { key: 'rtl', stylisPlugins: [stylisRTLPlugin] }
            : { key: 'mantine' }
        }
        theme={{
          colorScheme: 'light',
          primaryColor: 'cyan',
          defaultRadius: 5,
          fontFamily: 'Cairo, sans-serif',
        }}
        styles={{}}
      >
        <ModalsProvider>
          <div className="client">{children}</div>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
const ClientLayout = ({
  children,
  mode,
}: {
  children: any
  mode?: 'light' | 'dark'
}) => {
  const { data: session } = useSession()

  /*  useEffect(() => {
    console.log('session usEff', session)
    if (session?.user) {
      setToken()
    }
    const messaging = getMessaging(firebaseApp)
    isFCMSupported()
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      onMessage(messaging, payload => {
        console.log('Message received. ', payload)
        showNotification({
          onOpen: () => window.open(payload?.fcmOptions?.link),
          title: payload?.notification?.title,
          message: payload?.notification?.body,
        })
        // ...
      })
    }
  }, [])
  const [updateProfile, { loading }] = useMutation(UpdateProfileDocument, {
    onCompleted: async () => {
      await localforage.setItem('isFcmTokenSet', true)
       showNotification({
        title: t('profile'),
        message: t('profileUpdateSuccess'),
      })
      if (refetch) refetch()
      if (query.callback) {
        push(((query.callback || '') as string).replaceAll('*', '&'))
      } 
    },
    onError(error) {
      showNotification({
        title: t('error'),
        message: getErrorMessage(error).includes("username") ? t('usernameUsedBefore') : getErrorMessage(error),
        color: 'red',
        autoClose: false,
      }) 
    },,
  })
  const isFCMSupported = async () => {
    const checkIsSupported = await isSupported()
    
  }

  async function setToken() {
    try {
      const token = (await localforage.getItem(
        'fcm_token',
      )) as unknown as string
      const isFcmTokenSet = (await localforage.getItem(
        'isFcmTokenSet',
      )) as unknown as boolean
      console.log('token clientOnly', token)
      if (token && isFcmTokenSet != true) {
        updateProfile({
          variables: {
            data: {
              fcmToken: { set: token },
            },
            where: {
              id: session?.user.id,
            },
          },
        })
        //getMessage();
      }
    } catch (error) {
      console.log(error)
    }
  } */

  return (
    <ClientStyleProvider>
      <DashboardNotification />
      <Header mode={mode} />
      {children}
      <Footer />
    </ClientStyleProvider>
  )
}

export default ClientLayout

const DashboardNotification = () => {
  const { data } = useSession()
  const { t } = useTranslation('client')
  const isAdmin = data?.user.roleId === ROLE.admin.id
  const isTrainer = data?.user.roleId === ROLE.trainer.id
  const isOrgnizer = data?.user.roleId === ROLE.orgnizer.id
  const isMarketer = data?.user.roleId === ROLE.marketer.id
  const message = isAdmin
    ? t('adminGoToDashboard')
    : isTrainer
    ? t('trainerGoToDashboard')
    : isOrgnizer
    ? t('orgnizerGoToDashboard')
    : isMarketer && t('marketerGoToDashboard')

  if (!message) return <></>
  return (
    <div className="rounded-md bg-cyan-50 p-2">
      <div className="flex justify-center">
        <div className="ml-3  md:flex md:justify-between md:gap-6">
          <p className="text-sm text-cyan-700">{message}</p>
          <p className="mt-2 text-center text-sm md:mt-0">
            <Link href="/dashboard">
              <a className="whitespace-nowrap font-medium text-cyan-700 hover:text-cyan-600 underline">
                {t('goToDashboard')}
              </a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
