/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  FindNotificationDocument,
  ReadNotificationDocument,
} from '@/types/graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import { Popover, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { Fragment } from 'react'
import { useRouter } from 'next/router'

dayjs.extend(relativeTime)

const Notifications = () => {
  const { lang, t } = useTranslation('client')
  const isArabic = lang === 'ar'
  const { status } = useSession()
  const router = useRouter()
  const { data } = useQuery(FindNotificationDocument, {
    pollInterval: (5*60*1000),
    fetchPolicy: 'network-only',
    skip: status !== 'authenticated' || router.asPath.includes('payment'),
  })
  const [readNoti] = useMutation(ReadNotificationDocument)
  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button
            className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500"
            onClick={() => {
              if (
                (data?.findManyNotification.filter(n => !n.read).length || 0) >
                0
              )
                readNoti()
            }}
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-5 w-5" aria-hidden="true" />
            <Badge
              number={
                data?.findManyNotification.filter(n => !n.read).length || 0
              }
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={classNames(
                'absolute z-10 transform mt-3 px-2 w-screen max-w-xs sm:px-0',
                isArabic ? 'translate-x-3/4' : ' -translate-x-3/4',
              )}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden max-h-96 overflow-y-scroll">
                <div className="bg-white">
                  {data?.findManyNotification.map(noti => (
                    <div
                      key={noti.id}
                      className={classNames(
                        'rounded-md hover:bg-gray-50 transition ease-in-out duration-150 py-2',
                        !noti.read ? 'bg-cyan-50' : '',
                      )}
                    >
                      <a href={noti.link || '#'}>
                        <p className="px-4 text-base font-medium text-gray-900">
                          {noti.title}
                        </p>
                        <p className="px-4 text-sm text-gray-500">
                          {noti.message}
                        </p>
                        <p className="px-4 text-xs text-gray-500">
                          {dayjs(noti.createdAt).fromNow(true)}
                        </p>
                      </a>
                    </div>
                  ))}
                  {data?.findManyNotification.length === 0 && (
                    <span className="-m-3 p-3 block rounded-md  transition ease-in-out duration-150">
                      <p className="text-base font-medium text-gray-900 p-2">
                        {t('noNotifications')}
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default Notifications

const Badge = ({ number }: { number: number }) =>
  number ? (
    <div className="absolute -top-1 left-4">
      <span className="inline-flex items-center p-1 w-4 h-4  justify-center bg-opacity-80  rounded-full text-xs font-medium bg-cyan-100 text-cyan-800">
        {number}
      </span>
    </div>
  ) : null
